"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initGallery = void 0;
const FOCUSED_CLASSNAME = "focused";
function getGallery() {
    return document.getElementById("gallery");
}
function getGalleryList() {
    return document.querySelector("#gallery ul");
}
function getGalleryNavButtons() {
    return [
        document.getElementById("gallery-prev"),
        document.getElementById("gallery-next"),
    ];
}
function getFocusedEntry() {
    return document.querySelector(`#gallery li.${FOCUSED_CLASSNAME}`);
}
function clearFocus() {
    getFocusedEntry()?.classList?.remove(FOCUSED_CLASSNAME);
}
function focusEntry(element) {
    clearFocus();
    element?.classList?.add(FOCUSED_CLASSNAME);
}
function isElementFocused(element) {
    return element?.classList?.contains(FOCUSED_CLASSNAME) ?? false;
}
function initGallery() {
    const gallery = getGallery();
    const galleryList = getGalleryList();
    const allEntries = galleryList.querySelectorAll("li");
    allEntries.forEach((entry) => {
        [entry, entry.querySelector("img")].forEach((element) => element?.addEventListener("click", (e) => {
            if (!(e.target instanceof HTMLImageElement) &&
                !(e.target instanceof HTMLLIElement)) {
                return;
            }
            e.cancelBubble = true;
            if (isElementFocused(entry)) {
                clearFocus();
            }
            else {
                focusEntry(entry);
            }
        }));
    });
    const elementWidth = allEntries[0].getBoundingClientRect().width;
    const [prevButton, nextButton] = getGalleryNavButtons();
    function buttonClickCommon() {
        // Requires a timeout to avoid conflicts with the event being handled.
        setTimeout(() => gallery.scrollIntoView(), 0);
    }
    prevButton.addEventListener("click", () => {
        buttonClickCommon();
        focusEntry(getFocusedEntry()?.previousElementSibling);
        galleryList.scrollBy({
            left: -(elementWidth ?? 100),
            behavior: "smooth",
        });
    });
    nextButton.addEventListener("click", () => {
        buttonClickCommon();
        focusEntry(getFocusedEntry()?.nextElementSibling);
        galleryList.scrollBy({
            left: elementWidth ?? 100,
            behavior: "smooth",
        });
    });
}
exports.initGallery = initGallery;
