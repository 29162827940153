"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitFormWithRecaptchaForResult = exports.multipartFormEncode = void 0;
const recaptcha_1 = require("./recaptcha");
function multipartFormEncode(formData) {
    return [...formData.entries()]
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}
exports.multipartFormEncode = multipartFormEncode;
async function submitFormWithRecaptchaForResult(form, formData) {
    await (0, recaptcha_1.addRecaptchaToFormData)(formData, form.id.replaceAll("-", "_"));
    const body = multipartFormEncode(formData);
    const response = await fetch(form.action, {
        method: form.method,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        body,
    });
    const json = await response.json();
    return json["result"];
}
exports.submitFormWithRecaptchaForResult = submitFormWithRecaptchaForResult;
