"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addRecaptchaToFormData = void 0;
const RECAPTCHA_SITE_KEY = "6LcT-UEfAAAAALIl7NO1JPZvYuvVxDF6kyzYc1gH";
async function addRecaptchaToFormData(formData, formName) {
    const token = await grecaptcha.execute(RECAPTCHA_SITE_KEY, {
        action: `submit_${formName}`,
    });
    formData.append("g-recaptcha-response", token);
}
exports.addRecaptchaToFormData = addRecaptchaToFormData;
